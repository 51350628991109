const trans = {
  'Absence attack': 'Fraværsangreb',
  'account.search.placeholder': 'Søg på navn...',
  'accounts': 'Regnskaber',
  'actions': 'handlinger',
  'activateTestsInApp': 'Aktiver i app',
  'Add care provider for referral / consultation': 'Brug plejer-id til at henvise (f.eks. GSAPP)',
  'Add comment': 'Tilføj note og skriv af',
  'Add note': 'Tilføj note',
  'Add recommendation': 'Tilføj anbefaling',
  'Add summary': 'Tilføj oversigt',
  'Add-voucher': 'Ny kode',
  'add_referal': 'Tilføje',
  'add_sign_note_button': 'Tilføj / Signer',
  'addComment': 'Tilføj en kommentar',
  'admin': 'Admin',
  'administrator': 'Administrator',
  'age': 'Alder',
  'Aggressivness': 'Aggressivitet',
  'agreement': 'Jeg accepterer vilkårene og betingelserne',
  'agreement_1': 'Jeg er enig med',
  'agreement_2': 'Vilkår og betingelser',
  'agreement_3': 'Fortrolighedspolitik',
  'agreement_4': 'Forskningsbetingelserne',
  'alcoholNoteRisk': 'Kvinder, der scorer 3 eller højere, drikker over anbefalede grænser og har øget risiko for skade, mens mænd skal have 4 eller højere for det samme. Kilde:<i> Survey of Healthcare Experiences of Patients, 2008, VA Office of Quality and Performance.</i><br /><br /> Når punkterne alle er fra Q1 alene (hvor Q2 og Q3 er 0), kan det antages, at patienten drikker under anbefalede grænser, og yderligere undersøgelse bør udføres med resultater fra de sidste par måneder for at bekræfte nøjagtigheden.',
  'Alkohol (Audit-C) och rökning': 'Alkohol (Audit-C) og rygning',
  'all': 'Alle',
  'All data related to this user will be erased. This action can be undone.': 'Alle data relateret til denne bruger vil blive slettet. Denne handling kan fortrydes.',
  'allAccounts': 'Alle konti',
  'Allmänt': 'Generel',
  'allPatients': 'Alle patienter',
  'Alzheimer\'s disease with cerebrovascular injury - AD + VaD': 'Alzheimers sygdom med cerebrovaskulær skade - AD + VaD',
  'Alzheimer\'s disease, mild dementia - Early onset': 'Alzheimers sygdom, let demens - Tidlig debut',
  'Alzheimer\'s disease, mild-moderate dementia - Late onset': 'Alzheimers sygdom, let-moderat demens - Sen debut',
  'Alzheimer\'s disease, prodromal - Amnestic MCI': 'Alzheimers sygdom, prodromal - Amnestisk MCI',
  'amnesis': 'Amnesis',
  'anamnesis': 'Anamnese',
  'Anamnesis': 'Anamnese',
  'anamnesisFlex': 'Anamnese',
  'anamnesisFlexNew': 'Anamnese',
  'anamnesisFlexNew.categories.habits': 'Levevaner',
  'anamnesisFlexNew.categories.medical': 'Medicinsk baggrund',
  'anamnesisFlexNew.categories.screening': 'Screening',
  'anamnesisFlexNew.categories.social': 'Livsstil og symptomer',
  'anamnesisSettings': 'Anamneseafsnit skal medtages',
  'and': 'og',
  'Android_version': 'Android app',
  'Answer': 'Svar',
  'answer': 'Svar',
  'Answer was not provided!': 'Svar blev ikke givet!',
  'answer_skipped_idk': 'Sprang over på grund af svaret &quot;Jeg ved det ikke&quot; på forrige spørgsmål',
  'answer_skipped_no': 'Sprang over på grund af at svare &quot;Nej&quot; på spørgsmålet',
  'Answered by': 'Besvaret af',
  'answered_as_not_relevant': 'Svar: Ved ikke/ikke relevant',
  'Anxiety': 'Angst',
  'Appetite control': 'Appetit kontrol',
  'appointment-with': 'Aftale med',
  'appointments': 'Udnævnelser',
  'April': 'April',
  'Archive user': 'Arkivbruger',
  'Are you sure you want to archive this user?': 'Er du sikker på, at du vil arkivere brugeren?',
  'Are you sure you want to completely delete this user?': 'Er du sikker på, at du vil slette denne bruger fuldstændigt?',
  'Are you sure you want to delete this user?': 'Er du sikker på, at du vil slette brugeren?',
  'Are you sure you want to restore this user?': 'Er du sikker på, at du vil gendanne denne bruger?',
  'assistant_nurse': 'Hjælpesygeplejerske',
  'Associated symptoms': 'Tilknyttede symptomer',
  'At': 'Kl.',
  'attempt': 'Opgave',
  'Attempt': 'Forsøg',
  'atTime': 'på',
  'August': 'august',
  'availability': 'Tilgængelighed',
  'Availability': 'Tilgængelighed',
  'availability-page.avails': 'Ledige tider',
  'availability-page.text': 'Vælg plus-ikonet for at indstille din tilgængelighed for en valgt dag. Fjernelse af tilgængelighed for et slot, som allerede har en bekræftet patientaftale, vil ikke fjerne patientens aftale. Aftaler skal aflyses',
  'availability-page.title': 'Mine aftaler',
  'available times': 'Ledige tider',
  'Average of similar healthy patients': 'Gennemsnit af lignende raske patienter',
  'back': 'Tilbage',
  'Balance': 'balance',
  'bankId': 'Bank-id',
  'basicInformation': 'Grundlæggende oplysninger',
  'Behaviour': 'Opførsel',
  'birthDate': 'Fødselsdato',
  'Bladder control': 'Blærekontrol',
  'Body perception': 'Kropsopfattelse',
  'booked meetings': 'Bookede møder',
  'calendar': 'Kalender',
  'Call': 'Ring',
  'Call now': 'Ring nu',
  'Cam': 'Cam',
  'cancel': 'Afbestille',
  'Care provider with access to patient account': 'Plejeudbyder med adgang til patientkonto',
  'careGiver': 'Medicinsk personale',
  'Category': 'Kategori',
  'change': 'Lave om',
  'child': 'Barn',
  'clear': 'Klar',
  'clinical_pharmacologist': 'Klinisk farmakolog',
  'close-panel': 'Luk panelet',
  'coding test hint': '1 point for hver 10 rigtige',
  'cognitive': 'Kognitiv test (GSCT)',
  'Cognitive': 'Kognitiv test (GSCT)',
  'Cognitive Disease': 'Kognitiv sygdom',
  'Cognitive test': 'Kognitiv test',
  'Cognitive test completed': 'Kognitiv test gennemført',
  'Collapse': 'Bryder sammen',
  'common_error_message': 'Der opstod en ukendt fejl.',
  'Complete': 'Komplet',
  'Completed': 'Færdiggjort',
  'Computability': 'Beregnelighed',
  'Confirm': 'Bekræfte',
  'Confirm new password': 'Bekræft ny adgangskode',
  'Confirm password': 'Bekræft kodeord',
  'Confusion episodes': 'Forvirringsepisoder',
  'Consent obtained': 'Samtykke opnået',
  'Construction': 'Konstruktion',
  'contacts': 'Kontaktpersoner',
  'contacts.heading.clinicalAssessmentsAndEvidence': 'Kliniske vurderinger og evidens',
  'contacts.heading.otherInquiries': 'Andre henvendelser',
  'contacts.heading.patientAdministrationAndAccounts': 'Administration &amp; patientdata',
  'contacts.heading.technologyProductPatientData': 'Teknologi, produkt &amp; patientdata',
  'contacts.position.chiefMedicalOfficer': 'Medicinsk ansvarlig læge',
  'contacts.position.headOfClinicalOperationsAndImplementations': 'Ansvarlig for implementeringer',
  'contacts.position.headOfTechnologyAndProductDevelopment': 'Chef for teknologi og produktudvikling',
  'contactSupport.btn': 'Kontakt support',
  'contactSupport.text': 'Kontakt os og vi kontakter os hurtigst muligt',
  'Correct': 'Korrekt',
  'country': 'Land',
  'country-sv': 'Sverige',
  'country-us': 'hjort',
  'country-zh': 'Kina',
  'Course': 'Symptomforløb',
  'Create': 'skab',
  'create_fm': 'Opret en konto for at gennemføre Symptom Survey',
  'createCareGiveAccount': 'Opret konto til omsorgsgiver',
  'createdAt': 'Tilmeldingsdato',
  'createNewAccount': 'Opret konto',
  'createNewAccountSuccessMessage': 'Der er oprettet en konto',
  'createNewPatient': 'Skab patient',
  'createNewPatientSuccessMessage': 'Du har oprettet en ny patient',
  'da': 'Danish',
  'date': 'Dato',
  'Date answered': 'Dato besvaret',
  'dateAdded': 'Dato tilføjet',
  'dateScheduled': 'Dato planlagt',
  'Day': 'dag',
  'December': 'december',
  'Delete user': 'Slet bruger',
  'deleteAccount': 'Arkiv konto',
  'deletedAccounts': 'Arkiverede konti',
  'deletedPatients': 'Arkiverede patienter',
  'deletedPatients.counter.plural': 'Arkiverede patienter',
  'deletedPatients.counter.singular': 'Arkiveret patient',
  'deletePatient': 'Arkiv patient',
  'Delusions': 'Vrangforestillinger',
  'Dementia': 'Demens',
  'Depression': 'Depression',
  'deselect-all-and-save': 'Fravælg alle og gem',
  'Details': 'detaljer',
  'diagnosis': 'Diagnose',
  'diastolic-pressure': 'Mit sædvanlige diastoliske tryk',
  'doctor': 'Læge',
  'documents': 'Understøttende dokumenter',
  'documents.researchAndEvidence': 'Forskning og evidens',
  'Download PDF': 'Download PDF',
  'download_pdf_report': 'PDF rapport',
  'downloaded': 'Downloadet',
  'downloadReportModalBtn': 'Download rapport',
  'Downloads': 'Downloads',
  'Draw': 'Tegne',
  'Duration in years': 'Symptomvarighed (år)',
  'edit history': 'Rediger historik',
  'Edit transcription': 'Rediger transskription',
  'Edit-answer': 'Rediger svar',
  'editAccount': 'Rediger konto',
  'editAccountSuccessMessage': 'Redigeringer gemt',
  'editor': 'redaktør',
  'editPatient': 'Rediger patient',
  'editPatientDetails': 'Administration',
  'editPatientSuccessMessage': 'Redigeringer gemt',
  'ef': 'Eksekutiv funktion',
  'ef-description': 'EF refererer til din evne til funktioner af højere orden, såsom planlægning, organisering, evaluering osv.',
  'email': 'E-mail',
  'Email': 'E-mail',
  'emailInstructions': 'E-mail instruktioner',
  'emailLoginCodeInstructions': 'Patientens login-kode',
  'emailLoginInstructions': 'E-mail-instruktioner og loginoplysninger',
  'emailNote': 'Bemærk: Sørg for, at e-mail-korrespondance med patienten er godkendt',
  'emailSettings': 'E-mail-indstillinger',
  'Emotional Instability': 'Følelsesmæssig ustabilitet',
  'Emotional variation': 'Følelsesmæssig variation',
  'en': 'engelsk',
  'endocrinologist': 'Endokrinolog',
  'Enter-code': 'Indtast kode',
  'EnterRelativeEmail': 'Indtast e-mail til pårørende for invitation',
  'EnterRelativePhoneNumber': 'Indtast telefonnummer til pårørende for invitation',
  'error_confirm': 'Kodeordene er ikke ens',
  'error_password': 'Adgangskoden skal have mindst 8 tegn og også indeholde specielle symboler (såsom ! og ?)',
  'errors.loginForm.invalidPNRFormat': 'Forkert personnummer',
  'errors.loginForm.invalidPNRLength': 'Brug 12 numre i dit personlige nummer',
  'excelReport': 'Hent Excel-rapport',
  'Expect': 'Forventer',
  'familyFormNote1': 'For at bruge Geras Solutions-appen og gennemføre Symptom Survey skal du oprette en konto.',
  'familyFormNote2': ' Vi har brug for din godkendelse til at håndtere dine oplysninger nedenfor.',
  'familyMember': 'Familiemedlem',
  'familySurvey': 'Familieundersøgelse',
  'February': 'februar',
  'Female': 'Kvinde',
  'female': 'Kvinde',
  'Field must not be empty': 'Feltet er obligatorisk',
  'File was generated. Please click button below to download it': 'Filen blev genereret. Klik venligst på knappen nedenfor for at downloade den.',
  'Filter patients by name': 'Filtrer patienter efter navn',
  'filter-patients-form.gender.title': 'Filtrer patienter efter køn',
  'filter-patients-form.reset-filter': 'Nulstil filter til standard',
  'filter-patients-form.status.title': 'Filtrer patienter efter status',
  'filter.accounts.admins': 'Admin',
  'filter.accounts.all': 'Alle konti',
  'filter.accounts.doctors': 'Læge',
  'filter.accounts.nurses': 'Amme',
  'filter.accounts.superAdmins': 'Super admin',
  'filter.all': 'Alle patienter',
  'filter.appointments': 'Hold næste møde',
  'filter.cognitive': 'Gennemført test',
  'filter.month': 'Måned',
  'filter.recommendations': 'Har anbefalinger',
  'filter.week': 'Uge',
  'filter.year': 'År',
  'filters': 'Filtre',
  'Fine motor skills': 'Fin motorik, færdigheder',
  'First name': 'Fornavn',
  'firstName': 'Fornavn',
  'focusNoteRisk': 'Dette er baseret på valideret ADHD Self-Report Scale (ASRS), hvor hvert spørgsmål har sine egne bedømmelseskriterier. Spørgsmål 1-3 giver 1 point hver, hvis patienten svarede &quot;Nogle gange&quot; eller oftere. Spørgsmål 4-6 giver 1 point hver, hvis patienten svarede&quot; Ofte &quot;eller&quot; Meget ofte &quot;. Med en samlet score på 4 eller mere viser patienten symptomer, der minder meget om ADHD hos voksne og berettiger yderligere undersøgelse',
  'Forget your password?': 'Glemt din adgangskode?',
  'Forgot Password': 'Glemt adgangskode',
  'Friday': 'Fredag',
  'friend': 'Ven',
  'Frontal': 'Frontal',
  'Frontal temporal dementia, behavioral variant - FTDbv': 'Frontal temporal demens, adfærdsvariant - FTDbv',
  'Fysisk hälsa': 'Somatisk status',
  'gender': ' Køn',
  'generalInformation': 'Generel information',
  'Generating PDF file process starting...': 'Genererer PDF-fil proces starter...',
  'geras_forget_pass': 'Skift din adgangskode her',
  'Go to video call': 'Gå til videoopkald',
  'greetingComponent.text': 'Søg, opret eller følg en patientteststatus',
  'greetingComponent.title': 'Hej',
  'Hallucinations': 'Hallucinationer',
  'Hand on screen': 'Hånden på skærmen',
  'Have you obtained verbal consent from the patient to create an account on their behalf?': 'Har du fået mundtligt samtykke fra patienten til at oprette en konto på deres vegne?',
  'HCP_version': 'Portal',
  'headerCreatePatient': 'Opret ny patient',
  'headerCreatePatientSteps.step1': 'Patientoplysninger',
  'headerCreatePatientSteps.step2': 'Vælg opgaver til patienten',
  'headerCreatePatientSteps.step3': 'Skræddersy sygehistorie spørgeskema',
  'heavy-alcohol': 'tung alkohol',
  'height': 'Højde',
  'hide preliminary results': 'Skjul foreløbige resultater',
  'high': 'Høj',
  'home': 'Hjem',
  'Humor': 'Humor',
  'idk': 'Jeg ved det ikke',
  'ieWarning': 'Denne funktion virker ikke på din nuværende browser. Brug venligst en ny version af Edge, Firefox, Chrome eller Safari for at bruge denne funktion.',
  'If': 'Hvis',
  'image': 'billede',
  'Incorrect': 'Ukorrekt',
  'Indicative reference library': 'Vejledende referencebibliotek',
  'information_gathering': 'Informationsindsamling',
  'initialQuestionnaire': 'Indledende spørgeskema',
  'Initiative': 'Initiativ',
  'Insight': 'Indsigt',
  'institution': 'Institution',
  'institutionSettings': 'Fælles institution',
  'iOS_version': 'iOS app',
  'January': 'januar',
  'journalNotes': 'Journalnotater',
  'July': 'juli',
  'June': 'juni',
  'l': 'Sprog',
  'l-description': 'Dette refererer til din evne til at forstå og udtrykke mundtligt og skriftligt sprog og finde ord og navne efter kategori eller lyd.',
  'landingPage.tile.cta': 'Opret patient og send test',
  'landingPage.tile.heading.createPatient': 'Opret ny patient',
  'landingPage.tile.heading.searchPatient': 'Søg efter patient',
  'landingPage.tile.searchInputPlaceholder': 'Skriv patientens navn, personnummer eller patient-id',
  'Language understanding': 'Sprogforståelse',
  'Language variation': 'Sprogvariation',
  'Last name': 'Efternavn',
  'Last Update': 'Sidste ændring',
  'lastName': 'Efternavn',
  'latest score': 'Seneste testresultat',
  'latest-patients': 'Seneste patienter',
  'Leave call': 'Forlad opkaldet',
  'Lewybodydemens - DLB': 'Lewybodydemens - DLB',
  'light-alcohol': 'let alkohol',
  'Logging in...': 'Logger ind...',
  'Logic': 'Logik',
  'Login': 'Log på',
  'Login with': 'Login med',
  'loginCode.expires': 'Udløber',
  'loginCode.prolong': 'Forny login-kode',
  'loginCode.refreshSuccessMessage': 'Loginkoden blev opdateret',
  'loginCode.title': 'Login kode',
  'loginForm.errors.pnrIsNotValid': 'Brug 12 numre i dit personlige nummer',
  'loginForm.errors.pwdIsInvalid': 'forkert kodeord',
  'logout': 'Log ud',
  'low': 'Lav',
  'Male': 'Han',
  'male': 'Han',
  'manager': 'Manager',
  'March': 'marts',
  'Mark as correct': 'Marker som korrekt',
  'Mark as incorrect': 'Markér som forkert',
  'mark-as-read': 'Marker som læst',
  'max': 'Maks',
  'max_if_all_answered': 'max. hvis alle svarede',
  'maxScoreExceeded': 'Maksimal score er 59',
  'May': 'Kan',
  'MCI.abbreviationDecoding': 'Mild kognitiv svækkelse',
  'Medicin': 'Medicin',
  'medicinNoteRisk': 'Se Socialstyrelsens liste over<a href="https://www.socialstyrelsen.se/globalassets/sharepoint-dokument/dokument-webb/ovrigt/aldre-olampliga-lakemedel-for-aldre-lista.pdf" target="_blank"> Medicin, der bør undgås hos ældre</a> og den<a href="https://www.socialstyrelsen.se/globalassets/sharepoint-dokument/artikelkatalog/ovrigt/2018-12-37.pdf" target="_blank"> Diagnostisk tjekliste for nødvendigt stofbrug hos ældre</a> .<br /><br /> Lægemidler med antikolinerge virkninger: Akineton, Alimazin, Amitriptylin, Anafrani, l Atarax, Atropin, Buscopan, Calma, Clozapin, Detrusitol, Ditropan, Durbis, Egazil, Emselex, Glykopyrroniumbromid, Hydroxizin, Hydroxyzine, Kentera, Keminpratogan, Lepongian, Klomipratogan, Klomipratogan, Kentera, Levomepromazin, Ludiomil, Metylskopolamin, Morfin-Skopolamin, Norflex, Norgesic, Nozinan, Oxikodon-hyoscin, Oxybutynin, Pargitan, Postafen, Proklorperazin, Prometazin, Robinul, Saroten, Scopoderm, Sensaval, Sialanar, Spasmofen, Tolvegteral, Tolin, Tolin, Tolveger TOVIAZ, Truxal, Vesicare og Vesikur',
  'medium-heavy-alcohol': 'medium tung alkohol',
  'Meeting with': 'Møde med',
  'meetings': 'Møder',
  'Meetings': 'Møder',
  'meetings today': 'Møder i dag',
  'meetings-page.title': 'Mine møder',
  'Memory': 'Hukommelse',
  'Mental hälsa (PHQ-9)': 'Mental sundhed/depression (PHQ-9)',
  'mentalNoteRisk': 'Spørgsmål 9: &quot;Tanker om, at du ville være bedre stillet død eller at skade dig selv på en eller anden måde?&quot; - Er blevet besvaret positivt, vurder selvmordsrisiko.',
  'Mic': 'Mic',
  'mildRisk': 'Mild',
  'Mimic': 'Mimik',
  'min': 'min',
  'minimalRisk': 'Minimal eller ingen',
  'minutes': 'Minutes',
  'modal.bankId.description': 'For at logge ind skal du starte Mobil BankID-applikationen på din enhed',
  'modal.bankId.title': 'Åbn Mobil BankID',
  'modal.confirmation.refreshLoginCode': 'Vil du virkelig udføre denne handling?',
  'moderateRisk': 'Moderat',
  'moderateSeverRisk': 'Moderat svær',
  'Monday': 'Mandag',
  'Month': 'Måned',
  'Muscle tone': 'Muskeltonus',
  'name': 'Navn',
  'Naming ability': 'Navneevne',
  'neuropsychologist': 'Neuropsykolog',
  'New password': ' nyt kodeord',
  'new_physiotherapist': 'Fysioterapeut/Fysioterapeut',
  'newAccount': 'Ny konto',
  'newPatient': 'Ny patient',
  'next meeting': 'næste møde',
  'Next meeting in': 'Næste møde kl',
  'Next meeting with': 'Næste møde med',
  'No': 'Ingen',
  'No answer': 'Intet svar',
  'No meeting found': 'Ingen møder fundet',
  'No more meetings for today': 'Ikke flere møder i dag',
  'no results': 'Ingen resultater',
  'No upcoming meetings': 'Ingen kommende møder',
  'no-avail-set-for-this-time': 'Ingen tilgængelighed angivet for dette tidspunkt',
  'no-patients': 'Ingen patienter fundet',
  'no-referrals-currently': 'Ingen henvisninger i øjeblikket',
  'Normal': 'Normal',
  'Not completed': 'Ikke afsluttet',
  'Not Requested': 'Ikke anmodet',
  'not_relevant': 'Ikke relevant',
  'note': 'Note',
  'note-form.text': 'Noter vil kun blive vist til fagfolk, ikke patienten',
  'note_type_1': 'Det maksimale point for denne øvelse er 3 point. Alle forsøg vil blive scoret',
  'note_type_10': 'Det maksimale point for denne øvelse er 2 point. Alle forsøg vil blive scoret',
  'note_type_11': 'Det maksimale point for denne øvelse er 5 point. Alle forsøg vil blive scoret',
  'note_type_12': 'Det maksimale point for denne øvelse er 2 point. Alle forsøg vil blive scoret',
  'note_type_13': 'Det maksimale point for denne øvelse er 6 point. Kun det andet forsøg vil blive scoret. Det første forsøg er en prøveprøve.',
  'note_type_14': 'Det maksimale point for denne øvelse er 5 point. Alle forsøg vil blive scoret',
  'note_type_15': 'Det maksimale point for denne øvelse er 5 point. Alle forsøg vil blive scoret',
  'note_type_16': 'Det maksimale point for denne øvelse er 5 point. Alle forsøg vil blive scoret',
  'note_type_2': 'Det maksimale point for denne øvelse er 1 point. Kun den bedste blandt alle forsøg vil blive scoret',
  'note_type_3': 'Det maksimale point for denne øvelse er 5 point. Alle forsøg vil blive scoret',
  'note_type_4': 'Det maksimale point for denne øvelse er 1 point. Alle svar skal være rigtige for at opnå 1 point.',
  'note_type_5': 'Det maksimale point for denne øvelse er 5 point. Alle svar skal være rigtige for at opnå 1 point. Kun første forsøg vil blive scoret.',
  'note_type_6': 'Det maksimale point for denne øvelse er 4 point. Alle forsøg vil blive scoret',
  'note_type_7': 'Det maksimale point for denne øvelse er 4 point. Alle forsøg vil blive scoret',
  'note_type_8': 'Det maksimale point for denne øvelse er 5 point. Alle forsøg vil blive scoret',
  'note_type_9': 'Det maksimale point for denne øvelse er 5 point. Kun første forsøg vil blive scoret.',
  'NotEmptyAndValid': 'Feltet må ikke være tomt og skal være gyldigt',
  'notes': 'Noter',
  'November': 'november',
  'nurse': 'Amme',
  'o': 'Orientering',
  'o-description': 'Orientering refererer til din evne til korrekt at lokalisere dig selv i rum og tid',
  'Obesity Class I': 'Fedme klasse I',
  'Obesity Class II': 'Fedme klasse II',
  'Obesity Class III': 'Fedme klasse III',
  'October': 'oktober',
  'of': 'af',
  'Operating speed': 'Driftshastighed',
  'Opinion': 'Udtalelse',
  'other': 'Andet',
  'other-relationship': 'Andet forhold',
  'Overweight': 'Overvægtig',
  'p-1': 'Ja, udtales / ofte = 2 pt',
  'p-2': 'Ja, noget / nogle gange = 1 point',
  'p-3': 'Nej = 0 point',
  'p-4': 'Ved ikke / kan ikke dømme = Ikke relevant',
  'parent': 'Forælder',
  'Parietal / Temporal': 'Parietal / Temporal',
  'Parkinson\'s disease with dementia - PDD': 'Parkinsons sygdom med demens - PDD',
  'partner': 'Partner',
  'Password': 'Adgangskode',
  'passwordNote': 'Dit kodeord skal have 8 tegn og indeholde mindst 1 specialtegn',
  'past available times': 'Tidligere ledige tider',
  'past meetings': 'Tidligere møder',
  'Patient left test ahead of time': 'Patienten forlod testen før tid',
  'patient-appointments': 'Patientaftaler',
  'patient.note.edit': 'Rediger note',
  'patient.questionnaire-result.completed': 'Færdiggjort',
  'patient.questionnaire-result.not-completed': 'Ikke afsluttet',
  'patient.questionnaire-result.title': 'Opgavehistorik for patient før diagnose',
  'patientAccount': 'Patientkonto',
  'patientAccount.newPatient.patientTasks.cognitiveTest': 'Kognitiv test (GSCT)',
  'patientAccount.newPatient.patientTasks.deselectAll': 'Fravælg alle',
  'patientAccount.newPatient.patientTasks.medicalHistory': 'Sygehistorie',
  'patientAccount.newPatient.patientTasks.selectAll': 'Vælg alle',
  'patientAccount.newPatient.patientTasks.symptomSurvey': 'Symptomundersøgelse',
  'patientEmails': 'Patient e-mails',
  'Patientens koncentration - ADHD/ADD (ASRS)': 'Patientens evne til at fokusere - ADHD/ADD (ASRS)',
  'Patientens upplevda symtom': 'Opfattede kognitive symptomer',
  'patientForm.errors.emailIsNotValid': 'E-mail er ikke gyldig',
  'patientForm.errors.pnrIsNotValid': 'Personnummer skal være 12 numre',
  'patientInfo.patientStatus.assessmentReviewed': 'Vurdering gennemgået',
  'patientInfo.patientStatus.complementaryInfo': 'Supplerende info',
  'patientInfo.patientStatus.informationGathering': 'Informationsindsamling',
  'patientInfo.patientStatus.prelAssessment': 'Prel. vurdering',
  'patientInfo.patientStatus.reportGenerated': 'Rapport genereret',
  'patientInfo.patientStatus.resultsNotReviewed': 'Resultater ikke gennemgået',
  'patientInfo.patientStatus.reviewedResults': 'Gennemgåede resultater',
  'patientPhoneNumber': 'Patient telefonnummer',
  'patientPhoneNumber.lastSentTextMessage': 'Senest sendt besked',
  'patientPhoneNumber.noMobileNumber': 'Intet mobilnummer tilgængeligt',
  'patientPhoneNumber.sendLoginCode': 'Login kode og link til app',
  'patientPhoneNumber.successMessage': 'Sms&#39;en til patienten er nu sendt',
  'patientPhoneNumber.title': 'SMS-beskeder til patienten',
  'patients': 'Patienter',
  'patientsProfile': 'Patientens profil',
  'patientTasks': 'Patientopgaver',
  'pdf.age': 'Alder',
  'pdf.ageLowercase': 'Alder',
  'pdf.anamnesis': 'Anamnese',
  'pdf.cognitive': 'Kognitiv test',
  'pdf.createdThrough': 'Skabt igennem',
  'pdf.diagnos': 'Diagnose',
  'pdf.initialQuestionnaire': 'Indledende spørgsmål – Test hjernens sundhed',
  'pdf.main': 'Grundlag for undersøgelse',
  'pdf.name': 'Navn',
  'pdf.recommendations': 'anbefalinger',
  'pdf.summary': 'Opsummeret resultat og vurdering',
  'pdf.symptomSurvey': 'Symptomundersøgelse',
  'pdf.symptomSurvey.allFreeTextAnswers': 'Alle fritekstsvar',
  'pdf.symptomSurvey.allMultipleChoice': 'Alle flervalgssvar',
  'pdf.symptomSurvey.symptomSurveyPage': 'Side med symptomundersøgelse',
  'pdfReport': 'Generer rapport',
  'personalNumber': 'Personnummer',
  'phone': 'Telefonnummer',
  'physiotherapist': 'Fysioterapeut',
  'Planning': 'Planlægning',
  'Planning memory 1': 'Planlægningshukommelse 1',
  'Planning memory 2': 'Planlægningshukommelse 2',
  'Play drawing process': 'Spil tegning',
  'Please obtain verbal consent from the patient to create an account on their behalf.': 'Indhent venligst mundtligt samtykke fra patienten til at oprette en konto på deres vegne.',
  'Please wait': 'Vent venligst',
  'Please, click on the image below if you already have an app': 'Klik venligst på billedet nedenfor, hvis du allerede har en app',
  'Please, click on the image below to download app': 'Klik venligst på billedet nedenfor for at downloade appen',
  'pnumberFormat': 'ÅÅÅÅMMDD-XXXX',
  'Points': 'Points',
  'policy': 'Privatlivspolitik',
  'Posterior cortical atrophy - PCA': 'Posterior kortikal atrofi - PCA',
  'Practical ability': 'Praktisk evne',
  'previousMeetings': 'Tidligere møder',
  'Primary progressive non-fluid aphasia - PNFA': 'Primær progressiv ikke-væske afasi - PNFA',
  'profile-view-doctor': 'Dr.',
  'profile-view-neuropsychologist': 'Dr.',
  'profile-view-new_physiotherapist': 'PT.',
  'profile-view-nurse': 'Ingen.',
  'profile-view-psychologist': 'Dr.',
  'profile-view-therapist': 'OT',
  'Proximity memory 1': 'Nærhedshukommelse 1',
  'Proximity memory 2': 'Nærhedshukommelse 2',
  'psychologist': 'psykolog',
  'Question was skipped due to previous question answer': 'Spørgsmålet blev sprunget over på grund af tidligere svar på spørgsmålet ',
  'questionnaire': 'Spørgeskema',
  'Questionnaire': 'Spørgeskema',
  'Questions': 'spørgsmål',
  'quick_pick_button': 'Hurtigt valg',
  'radiologist': 'Radiolog',
  'Reaction time': 'Reaktionstid',
  'Recognition ability': 'Genkendelsesevne',
  'recommendation': 'Henstilling',
  'Recording not found': 'Optagelsen blev ikke fundet',
  'Recording of answers': 'Registrering af svar',
  'referrals': 'Henvisninger',
  'Regional Dysfunktionsprofil Patient': 'Regional dysfunktionsprofil',
  'Relationship': 'Forhold',
  'relationship': 'Vælg forhold',
  'relativeDegrees': '["Partner","Forælder","Barn","Søskende","Andet forhold","Ven"]',
  'Remote memory': 'Fjernhukommelse',
  'report-header': 'Foreløbige resultater',
  'report-header-cognitive': 'Kognitive funktioner',
  'report-high': 'Høj',
  'report-low': 'Lav',
  'report-tasks': 'Test øvelser',
  'reportExport': 'Rapportér eksport',
  'Requested': 'Anmodet',
  'requestFamilyPhoneLabel': 'Pårørendes mobilnummer',
  'requestFamilySurvey': 'Symptomundersøgelse',
  'Required': 'Påkrævet',
  'Reset counts': 'Slettede pile',
  'Reset password': 'Nulstille kodeord',
  'Responsiveness': 'Lydhørhed',
  'Restore user': 'Gendan bruger',
  'restoreAccount': 'Gendan konto',
  'restorePatient': 'Gendan patient',
  'restorePWDForm.errors.emailIsInvalid': 'Forkert e-mail',
  'Results': 'resultater',
  'Results for Review': 'Resultater for gennemgang',
  'riskIndicator': 'Risikoindikator',
  'riskIndicators': 'Risikoindikatorer',
  'riskInterval': 'Risikointerval',
  'riskLegend': 'Bemærk: Se klinisk undersøgelse under &quot;Dokumenter&quot; for mere info',
  'role': 'Rolle',
  'Room orientation': 'Rumorientering',
  'Saturday': 'lørdag',
  'save': 'Gemme',
  'save-and-send': 'Gem og send',
  'SCI.abbreviationDecoding': 'Subjektiv kognitiv svækkelse',
  'search': 'Søg',
  'search.results': 'resultater',
  'searchPatient': 'Skriv for at søge patient...',
  'seconds': 'Seconds',
  'Select profile': 'vælg profil',
  'Select the recommended path': 'Vælg den anbefalede sti',
  'select-all-and-save': 'Vælg alle og gem',
  'Select-discount': 'Vælg rabat',
  'selectCommentType': 'Vælg type kommentar',
  'selectCountry': 'Vælg land',
  'selectGender': 'Vælg køn',
  'selectLanguage': 'Vælg sprog',
  'selectRole': 'Vælg rolle',
  'Semantic dementia - SD': 'Semantisk demens - SD',
  'send': 'Sende',
  'Send': 'Sende',
  'Send symptom survey to relative': 'Send symptomundersøgelse til pårørende',
  'Sending email...': 'Sender e-mail...',
  'sendSymptomSurveySuccessMessage': 'Symptomundersøgelse sendt',
  'sendToPatientModalBtn': 'Del med patienten',
  'sentToPatient': 'sendt til patienten',
  'September': 'september',
  'setNewPassword': 'Indstil ny adgangskode',
  'Severely underweight': 'Svært undervægtig',
  'severRisk': 'Alvorlig',
  'Shape sense': 'Formsans',
  'show preliminary results': 'Vis foreløbige resultater',
  'show_all_records': 'Vis alle poster',
  'showArchivedTests': 'Se arkiverede tests',
  'showNewTests': 'Vend tilbage til nye tests',
  'sibling': 'Søskende',
  'sidePanel.navigation.contact': 'Kontakt',
  'Sleep disturbance': 'Søvnforstyrrelser',
  'specialist': 'Specialist',
  'specialistComments': 'Specialistkommentarer',
  'specialistReviewing': 'Visningshistorik',
  'Speech clarity': 'Tale klarhed',
  'speech_therapist': 'Logopæd',
  'start': 'Start',
  'startPage.button.createPatient': 'Skab patient',
  'startPage.searchHint': 'Søg efter patient',
  'starts-in': 'starter i',
  'status': 'Status',
  'stm': 'Hukommelse',
  'stm-description': 'STM refererer til din evne til at holde en lille mængde information i tankerne i en kort periode',
  'Stress sensitivity': 'Stressfølsomhed',
  'stressNoteRisk': 'Enhver, der modtager mere end 18 point, kan risikere at lide af stress/træthedssyndrom. Det anbefales herefter, at patienten kontakter deres sundhedscenter/bedriftssundhedspleje eller leder for at drøfte testresultaterne.',
  'Subcortical': 'Subkortikal',
  'summary_results_and_assessment': 'Sammenfattende resultater og vurdering',
  'Sunday': 'Søndag',
  'superAdmin': 'Super admin',
  'survey': 'Undersøgelse',
  'Suspected': 'Mistænkt',
  'sv': 'svensk',
  'symptom survey': 'Symptomundersøgelse',
  'Symptom Survey for relatives': 'Symptomundersøgelse for pårørende',
  'symptom_survey.riskLegend.hint': 'Opsummerede pointer er vist i forhold til de spørgsmål, som en pårørende har besvaret. Høje point indikerer flere områder med forværring af kognitiv status. &quot;Ved ikke/kan ikke dømme&quot;-svar er ikke inkluderet. Maksimal point, når alle spørgsmål er besvaret, vises i parentes.',
  'SymptomSurvey_version': 'Symptomundersøgelse',
  'systolic-pressure': 'Mit sædvanlige systoliske tryk',
  'termsAndConditions': 'Vilkår og betingelser',
  'Test duration': 'Test varighed',
  'testSettings': 'Indstillinger for applikationstester',
  'testStatus.abortedOrError': 'Afbrudt',
  'testStatus.done': 'Tilgængelige resultater',
  'testStatus.initiated': 'sat i gang',
  'testStatus.notSent': 'Ikke aktiveret',
  'testStatus.sent': 'Aktiveret',
  'testStatus.viewed': 'Gennemgået',
  'testStatus.viewedWithError': 'Afbrudt/anmeldt',
  'Text chat': 'SMS chat',
  'text-chat': 'SMS chat',
  'Text-message history': 'Tekst-besked historie',
  'textMessageToPatient.BrainHealth': 'Hjernens sundhed',
  'textMessageToPatient.BrainHealth.messageExample': 'Via dette link kan du få adgang til værdifuld information om hjernens sundhed</br> https://www.minnesmottagningen.se/hall-hjarnan-frisk/hallhjarnanfrisk</br> Bemærk venligst! Du kan ikke svare på denne sms.',
  'textMessageToPatient.FeedBack': 'Anmod om feedback',
  'textMessageToPatient.FeedBack.messageExample': 'Det ville være værdifuldt for os, hvis du kunne besvare et par korte spørgsmål om din kontakt med os. Spørgsmålene tager maksimalt 2 minutter at besvare, og besvarelserne er fuldstændig anonyme.</br> Du besvarer spørgsmålene ved at klikke på dette link:</br> https://forms.gle/1vvzJU5Z6qfidV9J7</br> Venlig hilsen,</br> Minnesmottagningen.se</br> Bemærk: Du kan ikke besvare denne besked',
  'textMessageToPatient.loginCode.messageExample': 'Hent app&#39;en - minnesmottagningen.se/app</br> Log ind med kode: XXXXXX</br> Bemærk: Du kan ikke besvare denne besked',
  'textMessageToPatient.MissedAppointment': 'Mistet videoaftale',
  'textMessageToPatient.MissedAppointment.messageExample': 'Du gik glip af dit planlagte videomøde i dag.</br> For at undgå yderligere gebyrer, bedes du kontakte os for at flytte din tid.</br> Du kan kontakte os på 010-750 07 26, mandag til fredag, fra 09:00 til 15:00 eller på info@minnesmottagningen.se.</br> Venlig hilsen,</br> Minnesmottagningen.se</br> Bemærk: Du kan ikke besvare denne besked',
  'textMessageToPatient.NoFreeCard': 'Intet gratis kort - betalingslink',
  'textMessageToPatient.NoFreeCard.messageExample': 'Manglende betaling af patientgebyr.</br> Ved bestilling af tid blev oplysninger om frikort angivet forkert.</br> Derfor beder vi dig betale et patientgebyr på 300 kroner via følgende link:</br> https://buy.stripe.com/4gw17fa4r8eG8ScfZ3</br> Venlig hilsen,</br> Minnesmottagningen.se</br> Bemærk: Du kan ikke besvare denne besked',
  'textMessageToPatient.RefundForPatient': 'Refusion til patient - grundet frikort',
  'textMessageToPatient.RefundForPatient.messageExample': 'Refusion af patientgebyr.</br> Vi refunderer patientgebyret på 300 kroner, fordi vores check afslørede, at du har et gyldigt frikort.</br> Bemærk venligst, at det kan tage et par dage, før tilbagebetalingen vises på den betalingsmetode, du valgte ved bestilling af din tid.</br> Venlig hilsen,</br> Minnesmottagningen.se</br> Bemærk: Du kan ikke besvare denne besked',
  'textMessageToPatient.ReminderForMeeting': 'Mødepåmindelse',
  'textMessageToPatient.ReminderForMeeting.messageExample': 'Mødepåmindelse.</br> Dit videomøde begynder snart.</br> Log ind her for at starte videomødet:</br> Minnesmottagningen.se/patientportal</br> Venlig hilsen,</br> Minnesmottagningen.se</br> Bemærk: Du kan ikke besvare denne besked',
  'textMessageToPatient.ReviewedByDoctor': 'Analyseret af læge',
  'textMessageToPatient.ReviewedByDoctor.messageExample': 'Dine svar og testresultater er blevet gennemgået af en læge.</br> Du vil modtage resultaterne, vurderingen og anbefalingerne under dit planlagte videomøde.</br> Venlig hilsen,</br> Minnesmottagningen.se</br> Bemærk: Du kan ikke besvare denne besked',
  'textMessageToPatient.TestReminder': 'Testpåmindelse',
  'textMessageToPatient.TestReminder.messageExample': 'Handling påkrævet.</br> Der er ufuldstændige opgaver, der skal udføres. Log ind på appen for at afslutte de resterende dele.</br> www.Minnesmottagningen.se/app</br> Venlig hilsen,</br> Minnesmottagningen.se</br> Bemærk: Du kan ikke besvare denne besked',
  'textMessageToPatient.ThankYou': 'Gennemført hukommelsesvurdering',
  'textMessageToPatient.ThankYou.messageExample': 'Information er nu tilgængelig på patientportalen via følgende link:</br> www.minnesmottagningen.se/patientportal</br> Bemærk: Du kan ikke besvare denne besked',
  'textMessageToPatient.TriedToReachOut': 'Forsøgte at nå ud',
  'textMessageToPatient.TriedToReachOut.messageExample': 'Vi har forsøgt at nå dig.</br> Du kan kontakte os på 010-750 07 26, mandag til fredag, fra 09:00 til 15:00 eller på info@minnesmottagningen.se.</br> Venlig hilsen,</br> Minnesmottagningen.se</br> Bemærk: Du kan ikke besvare denne besked',
  'Thank you for choosing Geras Solutions!': 'Tak fordi du valgte Geras Solutions!',
  'The email to the patient has now been sent': 'Mailen til patienten er nu sendt',
  'therapist': 'Ergoterapeut',
  'Thought tempo': 'Tanketempo',
  'threeDots': '...',
  'Thursday': 'torsdag',
  'Time perception 1': 'Tidsopfattelse 1',
  'Time perception 2': 'Tidsopfattelse 2',
  'Time to take test': 'Tid til at tage testen',
  'time-used': 'Tid brugt',
  'title': 'Patientoplysninger',
  'To confirm invitation press button below': 'Tryk på knappen nedenfor for at bekræfte invitationen',
  'Today': 'i dag',
  'today': 'i dag',
  'Tomorrow': 'I morgen',
  'tomorrow': 'I morgen',
  'Total points': 'SAMLET POINT',
  'Total time': 'Samlet tid',
  'totalPatients.counter.plural': 'Patienter',
  'totalPatients.counter.singular': 'Patient',
  'transcription from': 'Original transskription',
  'Transcription of answers': 'Transskription af svar',
  'transcription to': 'Ny transskription',
  'try': 'Forsøg',
  'Tuesday': 'tirsdag',
  'Type here...': 'Skriv her...',
  'Type to search patient': 'Skriv for at søge patient',
  'Typical authentic patient cases, not scientifically validated': 'Typiske autentiske patienttilfælde, ikke videnskabeligt validerede',
  'Underweight': 'Undervægtig',
  'upcomingMeetings': 'Kommende møder',
  'username': 'Brugernavn',
  'va': 'Visuospatiale evner',
  'va-description': 'VSA henviser til din evne til at identificere objekter i et rum, og hvordan de er rumligt relaterede.',
  'Vascular dementia - VaD': 'Vaskulær demens - VaD',
  'Very severely underweight': 'Meget alvorlig undervægtig',
  'video-appointment': 'Video aftale',
  'video-call': 'Videoopkald',
  'voucherCode': 'Generer kuponkode',
  'voucherCodeStats': 'Kuponkodestatistik',
  'Waiting for user to join...': 'Venter på, at brugeren deltager...',
  'Walking pattern': 'Gåmønster',
  'We have e-mailed your password reset link': 'Hvis din konto findes i vores system, vil du modtage en e-mail med nulstilling af adgangskode.',
  'We strongly recommend do not reload the page.': 'Vi anbefaler på det kraftigste ikke at genindlæse siden.',
  'Wednesday': 'onsdag',
  'Week': 'Uge',
  'weight': 'Vægt',
  'welcome': 'Velkomst',
  'welcome_geras_1': 'Velkommen til Geras Solutions',
  'welcome_geras_2': 'Webportal for sundhedspersonale',
  'Why did you download Geras app?': 'Hvorfor downloadede du Geras-appen?',
  'whyGeras': 'Hvorfor downloadede du Geras-appen?',
  'will start in': 'vil starte i',
  'with': 'med',
  'wm': 'Opmærksomhed, koncentration, arbejdshukommelse',
  'wm-description': 'Disse er relateret til din korttidshukommelse og henviser til din evne til at manipulere og organisere information, der er gemt i din korttidshukommelse.',
  'words:': 'Ord: ',
  'Write patient’s name, personal number, or email': 'Skriv patientens navn, personnummer eller patient-id',
  'Write your message here': 'Skriv din besked her',
  'Write your opinion, which will be sent to the patient': 'Skriv din mening, som sendes til patienten',
  'writeComment': 'Skriv en kommentar',
  'Writing ability': 'Skriveevne',
  'years': 'flere år',
  'yearsOld': 'år gammel',
  'Yes': 'Ja',
  'You can restore this user later': 'Du kan genoprette patienten senere',
  'You successfully changed your password': 'Du har ændret din adgangskode',
  'zh': 'kinesisk',
};
 export default trans;