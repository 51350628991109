export type LanguageType = 'en' | 'sv' | 'da';

export interface TranlsationLanguage {
  key: LanguageType;
  inUse: boolean;
  translations: { [key: string]: string };
}

export interface TranlsationsState {
  languages: TranlsationLanguage[];
  translationsFetched: boolean;
}

export enum TranslationsActionTypes {
  SWITCH_TRANSLATION = '@@translations/SWITCH_TRANSLATION',
  FETCH_TRANSLATIONS = '@@translations/FETCH_TRANSLATIONS',
  FORCE_REFRESH_TRANSLATIONS = '@@translations/FORCE_REFRESH_TRANSLATIONS',
  FETCH_TRANSLATIONS_SUCCESS = '@@translations/FETCH_TRANSLATIONS_SUCCESS',
  FETCH_TRANSLATIONS_DONE = '@@translations/FETCH_TRANSLATIONS_DONE'
}

interface SwitchTranslationAction {
  type: typeof TranslationsActionTypes.SWITCH_TRANSLATION;
  language: string;
}

interface FetchTranslationsAction {
  type: typeof TranslationsActionTypes.FETCH_TRANSLATIONS;
}

export interface FetchTranslationSuccessPayload {
  lang: string;
  translations: { [key: string]: string };
}

interface FetchTranslationsSuccessAction {
  type: typeof TranslationsActionTypes.FETCH_TRANSLATIONS_SUCCESS;
  payload: FetchTranslationSuccessPayload;
}

interface FetchTranslationsDoneAction {
  type: typeof TranslationsActionTypes.FETCH_TRANSLATIONS_DONE;
}

interface ForceRefreshTranslationsAction {
  type: typeof TranslationsActionTypes.FORCE_REFRESH_TRANSLATIONS;
}

export type TranslationsActionType =
  SwitchTranslationAction |
  FetchTranslationsAction |
  FetchTranslationsDoneAction |
  ForceRefreshTranslationsAction |
  FetchTranslationsSuccessAction;
