const trans = {
  'Absence attack': 'Absence attack',
  'account.search.placeholder': 'Search by name...',
  'accounts': 'Accounts',
  'actions': 'Actions',
  'activateTestsInApp': 'Activate in app',
  'Add care provider for referral / consultation': 'Use care-giver ID to reffer (e.g. GSAPP)',
  'Add comment': 'Add note & sign off',
  'Add note': 'Add note',
  'Add recommendation': 'Add recommendation',
  'Add summary': 'Add summary',
  'Add-voucher': 'New code',
  'add_referal': 'Add',
  'add_sign_note_button': 'Add / Sign',
  'addComment': 'Add a comment',
  'admin': 'Admin',
  'administrator': 'Administrator',
  'age': 'Age',
  'Aggressivness': 'Aggressivness',
  'agreement': 'I agree with the terms and conditions',
  'agreement_1': 'I agree with the',
  'agreement_2': 'Terms & Conditions',
  'agreement_3': 'Privacy Policy',
  'agreement_4': 'Forskningsvillkoren',
  'alcoholNoteRisk': 'Women who score 3 or higher drink above recommended limits and are at increased risks for harm, while the men need to have 4 or higher for the same. Source: <i>Survey of Healthcare Experiences of Patients, 2008, VA Office of Quality and Performance.</i><br /><br />When the points are all form Q1 alone (with Q2 and Q3 being 0), it can be assumed that the patient is drinking below recommended limits and further investigation should be done with results from past few months to confirm accuracy.',
  'Alkohol (Audit-C) och rökning': 'Alcohol (Audit-C) and smoking',
  'all': 'All',
  'All data related to this user will be erased. This action can be undone.': 'All data related to this user will be erased. This action can be undone.',
  'allAccounts': 'All Accounts',
  'Allmänt': 'General',
  'allPatients': 'All Patients',
  'Alzheimer\'s disease with cerebrovascular injury - AD + VaD': 'Alzheimer\'s disease with cerebrovascular injury - AD + VaD',
  'Alzheimer\'s disease, mild dementia - Early onset': 'Alzheimer\'s disease, mild dementia - Early onset',
  'Alzheimer\'s disease, mild-moderate dementia - Late onset': 'Alzheimer\'s disease, mild-moderate dementia - Late onset',
  'Alzheimer\'s disease, prodromal - Amnestic MCI': 'Alzheimer\'s disease, prodromal - Amnestic MCI',
  'amnesis': 'Amnesis',
  'anamnesis': 'Anamnesis',
  'Anamnesis': 'Anamnesis',
  'anamnesisFlex': 'Anamnesis',
  'anamnesisFlexNew': 'Anamnesis',
  'anamnesisFlexNew.categories.habits': 'Living habits',
  'anamnesisFlexNew.categories.medical': 'Medical background',
  'anamnesisFlexNew.categories.screening': 'Screening',
  'anamnesisFlexNew.categories.social': 'Lifestyle & symtoms',
  'anamnesisSettings': 'Anamnesis sections to include',
  'and': 'and',
  'Android_version': 'Android app',
  'Answer': 'Answer',
  'answer': 'Answer',
  'Answer was not provided!': 'Answer was not provided!',
  'answer_skipped_idk': 'Skipped due to answering "I do not know" on previous question',
  'answer_skipped_no': 'Skipped due to answering "No" on question',
  'Answered by': 'Answered by',
  'answered_as_not_relevant': 'Answer: Do not know / not relevant',
  'Anxiety': 'Anxiety',
  'Appetite control': 'Appetite control',
  'appointment-with': 'Appointment with',
  'appointments': 'Appointments',
  'April': 'April',
  'Archive user': 'Archive user',
  'Are you sure you want to archive this user?': 'Are you sure you want to archive the user?',
  'Are you sure you want to completely delete this user?': 'Are you sure you want to completely delete this user?',
  'Are you sure you want to delete this user?': 'Are you sure you want to delete the user?',
  'Are you sure you want to restore this user?': 'Are you sure you want to restore this user?',
  'assistant_nurse': 'Assistant nurse',
  'Associated symptoms': 'Associated symptoms',
  'At': 'At',
  'attempt': 'Task',
  'Attempt': 'Attempt',
  'atTime': 'At',
  'August': 'August',
  'availability': 'Availability',
  'Availability': 'Availability',
  'availability-page.avails': 'Available times',
  'availability-page.text': 'Select the plus icon to set your availability for a selected day. Removing availability for a slot which already has a confirmed patient appointment, will not remove the patient\'s appointment. Appointments must be cancelle',
  'availability-page.title': 'My appointments',
  'available times': 'Available times',
  'Average of similar healthy patients': 'Average of similar healthy patients',
  'back': 'Back',
  'Balance': 'Balance',
  'bankId': 'Bank-ID',
  'basicInformation': 'Basic Information',
  'Behaviour': 'Behaviour',
  'birthDate': 'Date of birth',
  'Bladder control': 'Bladder control',
  'Body perception': 'Body perception',
  'booked meetings': 'Booked meetings',
  'calendar': 'calendar',
  'Call': 'Call',
  'Call now': 'Call now',
  'Cam': 'Cam',
  'cancel': 'Cancel',
  'Care provider with access to patient account': 'Care provider with access to patient account',
  'careGiver': 'Medical personell',
  'Category': 'Category',
  'change': 'change',
  'child': 'Child',
  'clear': 'Clear',
  'clinical_pharmacologist': 'Clinical Pharmacologist',
  'close-panel': 'Close panel',
  'coding test hint': '1 point for every 10 correct',
  'cognitive': 'Cognitive test (GSCT)',
  'Cognitive': 'Cognitive test (GSCT)',
  'Cognitive Disease': 'Cognitive Disease',
  'Cognitive test': 'Cognitive test',
  'Cognitive test completed': 'Cognitive test completed',
  'Collapse': 'Collapse',
  'common_error_message': 'An unknown error occurred.',
  'Complete': 'Complete',
  'Completed': 'Completed',
  'Computability': 'Computability',
  'Confirm': 'Confirm',
  'Confirm new password': 'Confirm new password',
  'Confirm password': 'Confirm password',
  'Confusion episodes': 'Confusion episodes',
  'Consent obtained': 'Consent obtained',
  'Construction': 'Construction',
  'contacts': 'Contacts',
  'contacts.heading.clinicalAssessmentsAndEvidence': 'Clinical assessments & evidence',
  'contacts.heading.otherInquiries': 'Other inquiries',
  'contacts.heading.patientAdministrationAndAccounts': 'Administration & patient data',
  'contacts.heading.technologyProductPatientData': 'Technology, product & patient data',
  'contacts.position.chiefMedicalOfficer': 'Medically responsible doctor',
  'contacts.position.headOfClinicalOperationsAndImplementations': 'Responsible for implementations',
  'contacts.position.headOfTechnologyAndProductDevelopment': 'Head of Technology and Product Development',
  'contactSupport.btn': 'Contact support',
  'contactSupport.text': 'Contact us and we will get in touch as soon as possible',
  'Correct': 'Correct',
  'country': 'Country',
  'country-sv': 'Sweden',
  'country-us': 'USA',
  'country-zh': 'China',
  'Course': 'Symptom course',
  'Create': 'Create',
  'create_fm': 'Create an account to complete Symptom Survey',
  'createCareGiveAccount': 'Create account for care giver',
  'createdAt': 'Sign Up Date',
  'createNewAccount': 'Create account',
  'createNewAccountSuccessMessage': 'An account has been created',
  'createNewPatient': 'Create patient',
  'createNewPatientSuccessMessage': 'You have created a new patient',
  'da': 'Danish',
  'date': 'Date',
  'Date answered': 'Date answered',
  'dateAdded': 'Date added',
  'dateScheduled': 'Date Scheduled',
  'Day': 'Day',
  'December': 'December',
  'Delete user': 'Delete user',
  'deleteAccount': 'Archive Account',
  'deletedAccounts': 'Archived Accounts',
  'deletedPatients': 'Archived Patients',
  'deletedPatients.counter.plural': 'Archived patients',
  'deletedPatients.counter.singular': 'Archived patient',
  'deletePatient': 'Archive patient',
  'Delusions': 'Delusions',
  'Dementia': 'Dementia',
  'Depression': 'Depression',
  'deselect-all-and-save': 'Deselect all and save',
  'Details': 'Details',
  'diagnosis': 'Diagnosis',
  'diastolic-pressure': 'My usual diastolic pressure',
  'doctor': 'Doctor',
  'documents': 'Supporting documents',
  'documents.researchAndEvidence': 'Research and evidence',
  'Download PDF': 'Download PDF',
  'download_pdf_report': 'PDF report',
  'downloaded': 'downloaded',
  'downloadReportModalBtn': 'Download report',
  'Downloads': 'Downloads',
  'Draw': 'Draw',
  'Duration in years': 'Symptom duration (years)',
  'edit history': 'Edit history',
  'Edit transcription': 'Edit transcription',
  'Edit-answer': 'Edit answer',
  'editAccount': 'Edit account',
  'editAccountSuccessMessage': 'Edits saved',
  'editor': 'editor',
  'editPatient': 'Edit patient',
  'editPatientDetails': 'Administration',
  'editPatientSuccessMessage': 'Edits saved',
  'ef': 'Executive functioning',
  'ef-description': 'EF refers to your ability for higher-order functions, such as planning, organizing, evaluating, etc.',
  'email': 'Email',
  'Email': 'Email',
  'emailInstructions': 'Email instructions',
  'emailLoginCodeInstructions': 'Patient\'s login code',
  'emailLoginInstructions': 'Email instructions and login credentials',
  'emailNote': 'Note: Ensure that email correspondence with patient is approved',
  'emailSettings': 'Email settings',
  'Emotional Instability': 'Emotional Instability',
  'Emotional variation': 'Emotional variation',
  'en': 'English',
  'endocrinologist': 'Endocrinologist',
  'Enter-code': 'Enter code',
  'EnterRelativeEmail': 'Enter email to relative for invitation',
  'EnterRelativePhoneNumber': 'Enter phone number to relative for invitation',
  'error_confirm': 'Passwords do not match',
  'error_password': 'Password must have a minimum of 8 characters and also contain special symbols (such as ! and ?)',
  'errors.loginForm.invalidPNRFormat': 'Incorrect personal number',
  'errors.loginForm.invalidPNRLength': 'Use 12 numbers in your personal number',
  'excelReport': 'Download Excel report',
  'Expect': 'Expect',
  'familyFormNote1': 'To use the Geras Solutions app and complete the Symptom Survey, you need to create an account.',
  'familyFormNote2': ' We need your approval to handle your information below.',
  'familyMember': 'Family member',
  'familySurvey': 'Family Survey',
  'February': 'February',
  'Female': 'Female',
  'female': 'Female',
  'Field must not be empty': 'Field is mandatory',
  'File was generated. Please click button below to download it': 'File was generated. Please click button below to download it.',
  'Filter patients by name': 'Filter patients by name',
  'filter-patients-form.gender.title': 'Filter patients by gender',
  'filter-patients-form.reset-filter': 'Reset filter to default',
  'filter-patients-form.status.title': 'Filter patients by status',
  'filter.accounts.admins': 'Admin',
  'filter.accounts.all': 'All accounts',
  'filter.accounts.doctors': 'Doctor',
  'filter.accounts.nurses': 'Nurse',
  'filter.accounts.superAdmins': 'Super admin',
  'filter.all': 'All patients',
  'filter.appointments': 'Have next meeting',
  'filter.cognitive': 'Completed Test',
  'filter.month': 'Month',
  'filter.recommendations': 'Have recommendations',
  'filter.week': 'Week',
  'filter.year': 'Year',
  'filters': 'Filters',
  'Fine motor skills': 'Fine motor ,skills',
  'First name': 'First name',
  'firstName': 'First Name',
  'focusNoteRisk': 'This is based on validated ADHD Self-Report Scale (ASRS), where each question has its own judging criteria.Questions 1-3 give 1 point each if the patient answered "Sometimes" or more often. Questions 4-6 give 1 point each if the patient answered" Often "or" Very often ". With a total score of 4 or more, the patient shows symptoms that are very similar to ADHD in adults and justifies further investigation',
  'Forget your password?': 'Forgot your password?',
  'Forgot Password': 'Forgot Password',
  'Friday': 'Friday',
  'friend': 'Friend',
  'Frontal': 'Frontal',
  'Frontal temporal dementia, behavioral variant - FTDbv': 'Frontal temporal dementia, behavioral variant - FTDbv',
  'Fysisk hälsa': 'Somatic status',
  'gender': 'Gender',
  'generalInformation': 'General Information',
  'Generating PDF file process starting...': 'Generating PDF file process starting...',
  'geras_forget_pass': 'Change your password here',
  'Go to video call': 'Go to video call',
  'greetingComponent.text': 'Search, create or follow a patients test status',
  'greetingComponent.title': 'Hello',
  'Hallucinations': 'Hallucinations',
  'Hand on screen': 'Hand on screen',
  'Have you obtained verbal consent from the patient to create an account on their behalf?': 'Have you obtained verbal consent from the patient to create an account on their behalf?',
  'HCP_version': 'Portal',
  'headerCreatePatient': 'Create new patient',
  'headerCreatePatientSteps.step1': 'Patient information',
  'headerCreatePatientSteps.step2': 'Select tasks for patient',
  'headerCreatePatientSteps.step3': 'Tailor medical history questionnaire',
  'heavy-alcohol': 'heavy alcohol',
  'height': 'Height',
  'hide preliminary results': 'Hide preliminary results',
  'high': 'High',
  'home': 'Home',
  'Humor': 'Humor',
  'idk': 'I do not know',
  'ieWarning': 'This feature does not work on your current browser. Please use a new version of Edge, Firefox, Chrome or Safari to use this feature.',
  'If': 'If',
  'image': 'Image',
  'Incorrect': 'Incorrect',
  'Indicative reference library': 'Indicative reference library',
  'information_gathering': 'Information gathering',
  'initialQuestionnaire': 'Initial Questionnaire',
  'Initiative': 'Initiative',
  'Insight': 'Insight',
  'institution': 'Institution',
  'institutionSettings': 'Shared insitution',
  'iOS_version': 'iOS app',
  'January': 'January',
  'journalNotes': 'Journal notes',
  'July': 'July',
  'June': 'June',
  'l': 'Language',
  'l-description': 'This refers to your ability to comprehend and express oral and written language, and find words and names by category or sound.',
  'landingPage.tile.cta': 'Create Patient & Send Test',
  'landingPage.tile.heading.createPatient': 'Create new patient',
  'landingPage.tile.heading.searchPatient': 'Search for patient',
  'landingPage.tile.searchInputPlaceholder': 'Write patient\'s name, personal number, or patient-id',
  'Language understanding': 'Language understanding',
  'Language variation': 'Language variation',
  'Last name': 'Last name',
  'Last Update': 'Last Update',
  'lastName': 'Last Name',
  'latest score': 'Latest test score',
  'latest-patients': 'Latest patients',
  'Leave call': 'Leave call',
  'Lewybodydemens - DLB': 'Lewybodydemens - DLB',
  'light-alcohol': 'light alcohol',
  'Logging in...': 'Logging in...',
  'Logic': 'Logic',
  'Login': 'Login',
  'Login with': 'Login with',
  'loginCode.expires': 'Expires',
  'loginCode.prolong': 'Renew login code',
  'loginCode.refreshSuccessMessage': 'Login code refreshed successfully',
  'loginCode.title': 'Login code',
  'loginForm.errors.pnrIsNotValid': 'Use 12 numbers in your personal number',
  'loginForm.errors.pwdIsInvalid': 'Incorrect password',
  'logout': 'Logout',
  'low': 'Low',
  'Male': 'Male',
  'male': 'Male',
  'manager': 'Manager',
  'March': 'March',
  'Mark as correct': 'Mark as correct',
  'Mark as incorrect': 'Mark as incorrect',
  'mark-as-read': 'Mark as read',
  'max': 'Max',
  'max_if_all_answered': 'max. if all answered',
  'maxScoreExceeded': 'Maximum score is 59',
  'May': 'May',
  'MCI.abbreviationDecoding': 'Mild cognitive impairment',
  'Medicin': 'Medication',
  'medicinNoteRisk': 'See the National Board of Health and Welfare\'s list of <a href="https://www.socialstyrelsen.se/globalassets/sharepoint-dokument/dokument-webb/ovrigt/aldre-olampliga-lakemedel-for-aldre-lista.pdf" target="_blank">Medicines that should be avoided with elderly</a> and the <a href="https://www.socialstyrelsen.se/globalassets/sharepoint-dokument/artikelkatalog/ovrigt/2018-12-37.pdf" target="_blank">Diagnostic checklist for necessary drug use with elderly</a>.<br /><br />Drugs with anticholinergic effects: Akineton, Alimemazin, Amitriptylin, Anafrani,l Atarax, Atropin, Buscopan, Calma, Clozapine, Detrusitol, Ditropan, Durbis, Egazil, Emselex, Glykopyrroniumbromid, Hydroxizin, Hydroxyzine, Kentera, Ketogan, Klomipramin, Leponex, Lergigan, Levomepromazine, Ludiomil, Metylskopolamin, Morfin-Skopolamin, Norflex, Norgesic, Nozinan, Oxikodon-hyoscin, Oxybutynin, Pargitan, Postafen, Proklorperazin, Prometazin, Robinul, Saroten, Scopoderm, Sensaval, Sialanar, Spasmofen, Tavegyl, Theralen, Tolterodin, Tolterodine, TOVIAZ, Truxal, Vesicare och Vesikur',
  'medium-heavy-alcohol': 'medium heavy alcohol',
  'Meeting with': 'Meeting with',
  'meetings': 'Meetings',
  'Meetings': 'Meetings',
  'meetings today': 'Meetings today',
  'meetings-page.title': 'My meetings',
  'Memory': 'Memory',
  'Mental hälsa (PHQ-9)': 'Mental Health/Depression (PHQ-9)',
  'mentalNoteRisk': 'Question 9: "Thoughts that you would be better off dead or of hurting yourself in some way?" - Has been answered positively, assess suicide risk.',
  'Mic': 'Mic',
  'mildRisk': 'Mild',
  'Mimic': 'Mimic',
  'min': 'Min',
  'minimalRisk': 'Minimal or none',
  'minutes': 'minutes',
  'modal.bankId.description': 'To log in, start the Mobile BankID application on your device',
  'modal.bankId.title': 'Open Mobile BankID',
  'modal.confirmation.refreshLoginCode': 'Do you really want to perform this action?',
  'moderateRisk': 'Moderate',
  'moderateSeverRisk': 'Moderately severe',
  'Monday': 'Monday',
  'Month': 'Month',
  'Muscle tone': 'Muscle tone',
  'name': 'Name',
  'Naming ability': 'Naming ability',
  'neuropsychologist': 'Neuropsychologist',
  'New password': 'New password',
  'new_physiotherapist': 'Physiotherapist/Sjukgymnast',
  'newAccount': 'New Account',
  'newPatient': 'New Patient',
  'next meeting': 'next meeting',
  'Next meeting in': 'Next meeting in',
  'Next meeting with': 'Next meeting with',
  'No': 'No',
  'No answer': 'No answer',
  'No meeting found': 'No meetings found',
  'No more meetings for today': 'No more meetings for today',
  'no results': 'No results',
  'No upcoming meetings': 'No upcoming meetings',
  'no-avail-set-for-this-time': 'No availability set for this time',
  'no-patients': 'No patients found',
  'no-referrals-currently': 'No referrals at the moment',
  'Normal': 'Normal',
  'Not completed': 'Not completed',
  'Not Requested': 'Not Requested',
  'not_relevant': 'Not relevant',
  'note': 'Note',
  'note-form.text': 'Notes will only be shown to professionals, not the patient',
  'note_type_1': 'The maximum points for this exercise is 3 point. All attempts will be scored',
  'note_type_10': 'The maximum points for this exercise is 2 point. All attempts will be scored',
  'note_type_11': 'The maximum points for this exercise is 5 point. All attempts will be scored',
  'note_type_12': 'The maximum points for this exercise is 2 point. All attempts will be scored',
  'note_type_13': 'The maximum points for this exercise is 6 points. Only the second attempt will be scored. The first attempt is a trial test.',
  'note_type_14': 'The maximum points for this exercise is 5 point. All attempts will be scored',
  'note_type_15': 'The maximum points for this exercise is 5 points. All attempts will be scored',
  'note_type_16': 'The maximum points for this exercise is 5 point. All attempts will be scored',
  'note_type_2': 'The maximum points for this exercise is 1 point. Only the best among all attempts will be scored',
  'note_type_3': 'The maximum points for this exercise is 5 point. All attempts will be scored',
  'note_type_4': 'The maximum points for this exercise is 1 point. All answers need to be correct to complete 1 point.',
  'note_type_5': 'The maximum points for this exercise is 5 point. All answers need to be correct to complete 1 point. Only first attempt will be scored.',
  'note_type_6': 'The maximum points for this exercise is 4 point. All attempts will be scored',
  'note_type_7': 'The maximum points for this exercise is 4 point. All attempts will be scored',
  'note_type_8': 'The maximum points for this exercise is 5 point. All attempts will be scored',
  'note_type_9': 'The maximum points for this exercise is 5 points. Only first attempt will be scored.',
  'NotEmptyAndValid': 'Field must not be empty and must be valid',
  'notes': 'Notes',
  'November': 'November',
  'nurse': 'Nurse',
  'o': 'Orientation',
  'o-description': 'Orientation refers to your ability to correctly locate yourself within space and time',
  'Obesity Class I': 'Obesity Class I',
  'Obesity Class II': 'Obesity Class II',
  'Obesity Class III': 'Obesity Class III',
  'October': 'October',
  'of': 'of',
  'Operating speed': 'Operating speed',
  'Opinion': 'Opinion',
  'other': 'Other',
  'other-relationship': 'Other relationship',
  'Overweight': 'Overweight',
  'p-1': 'Yes, pronounced / often = 2 pts',
  'p-2': 'Yes, something / sometimes = 1 pts',
  'p-3': 'No = 0 pts',
  'p-4': 'Don’t know / can’t judge = Not relevant',
  'parent': 'Parent',
  'Parietal / Temporal': 'Parietal / Temporal',
  'Parkinson\'s disease with dementia - PDD': 'Parkinson\'s disease with dementia - PDD',
  'partner': 'Partner',
  'Password': 'Password',
  'passwordNote': 'Your password must have 8 characters and include at least 1 special character',
  'past available times': 'Past available times',
  'past meetings': 'Past meetings',
  'Patient left test ahead of time': 'Patient left test ahead of time',
  'patient-appointments': 'Patient appointments',
  'patient.note.edit': 'Edit note',
  'patient.questionnaire-result.completed': 'Completed',
  'patient.questionnaire-result.not-completed': 'Not completed',
  'patient.questionnaire-result.title': 'Patient pre-diagnosis task history',
  'patientAccount': 'Patient account',
  'patientAccount.newPatient.patientTasks.cognitiveTest': 'Cognitive Test (GSCT)',
  'patientAccount.newPatient.patientTasks.deselectAll': 'Deselect All',
  'patientAccount.newPatient.patientTasks.medicalHistory': 'Medical history',
  'patientAccount.newPatient.patientTasks.selectAll': 'Select All',
  'patientAccount.newPatient.patientTasks.symptomSurvey': 'Symptom Survey',
  'patientEmails': 'Patient emails',
  'Patientens koncentration - ADHD/ADD (ASRS)': 'Patient’s ability to focus - ADHD/ADD (ASRS)',
  'Patientens upplevda symtom': 'Perceived cognitive symptoms',
  'patientForm.errors.emailIsNotValid': 'Email is not valid',
  'patientForm.errors.pnrIsNotValid': 'Personal number must be 12 numbers',
  'patientInfo.patientStatus.assessmentReviewed': 'Assessment reviewed',
  'patientInfo.patientStatus.complementaryInfo': 'Complementary info',
  'patientInfo.patientStatus.informationGathering': 'Information gathering',
  'patientInfo.patientStatus.prelAssessment': 'Prel. assessment',
  'patientInfo.patientStatus.reportGenerated': 'Report generated',
  'patientInfo.patientStatus.resultsNotReviewed': 'Results not reviewed',
  'patientInfo.patientStatus.reviewedResults': 'Reviewed results',
  'patientPhoneNumber': 'Patient phone number',
  'patientPhoneNumber.lastSentTextMessage': 'Last sent message',
  'patientPhoneNumber.noMobileNumber': 'No mobile number available',
  'patientPhoneNumber.sendLoginCode': 'Login code & link to app',
  'patientPhoneNumber.successMessage': 'The text message to the patient has now been sent',
  'patientPhoneNumber.title': 'Text-messages to patient',
  'patients': 'Patients',
  'patientsProfile': 'Patient\'s profile',
  'patientTasks': 'Patient tasks',
  'pdf.age': 'Age',
  'pdf.ageLowercase': 'age',
  'pdf.anamnesis': 'Anamnesis',
  'pdf.cognitive': 'Cognitive Test',
  'pdf.createdThrough': 'Created through',
  'pdf.diagnos': 'Diagnosis',
  'pdf.initialQuestionnaire': 'Introductory questions – Test brain health',
  'pdf.main': 'Basis for investigation',
  'pdf.name': 'Name',
  'pdf.recommendations': 'Recommendations',
  'pdf.summary': 'Summarized result and assesment',
  'pdf.symptomSurvey': 'Symptom Survey',
  'pdf.symptomSurvey.allFreeTextAnswers': 'All free text answers',
  'pdf.symptomSurvey.allMultipleChoice': 'All multiple-choice answers',
  'pdf.symptomSurvey.symptomSurveyPage': 'Symptom survey page',
  'pdfReport': 'Generate report',
  'personalNumber': 'Personal number',
  'phone': 'Phone number',
  'physiotherapist': 'Physiotherapist',
  'Planning': 'Planning',
  'Planning memory 1': 'Planning memory 1',
  'Planning memory 2': 'Planning memory 2',
  'Play drawing process': 'Play drawing',
  'Please obtain verbal consent from the patient to create an account on their behalf.': 'Please obtain verbal consent from the patient to create an account on their behalf.',
  'Please wait': 'Please wait',
  'Please, click on the image below if you already have an app': 'Please, click on the image below if you already have an app',
  'Please, click on the image below to download app': 'Please, click on the image below to download app',
  'pnumberFormat': 'YYYYMMDD-XXXX',
  'Points': 'Points',
  'policy': 'Privaci Policy',
  'Posterior cortical atrophy - PCA': 'Posterior cortical atrophy - PCA',
  'Practical ability': 'Practical ability',
  'previousMeetings': 'Previous meetings',
  'Primary progressive non-fluid aphasia - PNFA': 'Primary progressive non-fluid aphasia - PNFA',
  'profile-view-doctor': 'Dr.',
  'profile-view-neuropsychologist': 'Dr.',
  'profile-view-new_physiotherapist': 'PT.',
  'profile-view-nurse': 'Nr.',
  'profile-view-psychologist': 'Dr.',
  'profile-view-therapist': 'OT',
  'Proximity memory 1': 'Proximity memory 1',
  'Proximity memory 2': 'Proximity memory 2',
  'psychologist': 'Psychologist',
  'Question was skipped due to previous question answer': 'Question was skipped due to previous answer on question ',
  'questionnaire': 'Questionnaire',
  'Questionnaire': 'Questionnaire',
  'Questions': 'Questions',
  'quick_pick_button': 'Quick pick',
  'radiologist': 'Radiologist',
  'Reaction time': 'Reaction time',
  'Recognition ability': 'Recognition ability',
  'recommendation': 'Recommendation',
  'Recording not found': 'Recording not found',
  'Recording of answers': 'Recording of answers',
  'referrals': 'Referrals',
  'Regional Dysfunktionsprofil Patient': 'Regional Dysfunction Profile',
  'Relationship': 'Relationship',
  'relationship': 'Select relationship',
  'relativeDegrees': '["Partner","Parent","Child","Sibling","Other relationship","Friend"]',
  'Remote memory': 'Remote memory',
  'report-header': 'Preliminary results',
  'report-header-cognitive': 'Cognitive functions',
  'report-high': 'High',
  'report-low': 'Low',
  'report-tasks': 'Test exercises',
  'reportExport': 'Report export',
  'Requested': 'Requested',
  'requestFamilyPhoneLabel': 'Relatives mobile number',
  'requestFamilySurvey': 'Symptom survey',
  'Required': 'Required',
  'Reset counts': 'Deleted arrows',
  'Reset password': 'Reset password',
  'Responsiveness': 'Responsiveness',
  'Restore user': 'Restore user',
  'restoreAccount': 'Restore Account',
  'restorePatient': 'Restore Patient',
  'restorePWDForm.errors.emailIsInvalid': 'Incorrect email',
  'Results': 'Results',
  'Results for Review': 'Results for Review',
  'riskIndicator': 'Risk Indicator',
  'riskIndicators': 'Risk indicators',
  'riskInterval': 'Risk Interval',
  'riskLegend': 'Note: See clinical study under "Documents" for more info',
  'role': 'Role',
  'Room orientation': 'Room orientation',
  'Saturday': 'Saturday',
  'save': 'Save',
  'save-and-send': 'Save and send',
  'SCI.abbreviationDecoding': 'Subjective cognitive impairment',
  'search': 'Search',
  'search.results': 'Results',
  'searchPatient': 'Type to search patient...',
  'seconds': 'seconds',
  'Select profile': 'Select profile',
  'Select the recommended path': 'Select the recommended path',
  'select-all-and-save': 'Select all and save',
  'Select-discount': 'Select discount',
  'selectCommentType': 'Select type of comment',
  'selectCountry': 'Select country',
  'selectGender': 'Select gender',
  'selectLanguage': 'Select language',
  'selectRole': 'Select role',
  'Semantic dementia - SD': 'Semantic dementia - SD',
  'send': 'Send',
  'Send': 'Send',
  'Send symptom survey to relative': 'Send symptom survey to relative',
  'Sending email...': 'Sending email...',
  'sendSymptomSurveySuccessMessage': 'Symptom survey sent',
  'sendToPatientModalBtn': 'Share with patient',
  'sentToPatient': 'sent to patient',
  'September': 'September',
  'setNewPassword': 'Set new password',
  'Severely underweight': 'Severely underweight',
  'severRisk': 'Severe',
  'Shape sense': 'Shape sense',
  'show preliminary results': 'Show preliminary results',
  'show_all_records': 'Show all records',
  'showArchivedTests': 'See archived tests',
  'showNewTests': 'Return to new tests',
  'sibling': 'Sibling',
  'sidePanel.navigation.contact': 'Contact',
  'Sleep disturbance': 'Sleep disturbance',
  'specialist': 'Specialist',
  'specialistComments': 'Specialist comments',
  'specialistReviewing': 'Viewing History',
  'Speech clarity': 'Speech clarity',
  'speech_therapist': 'Speech therapist',
  'start': 'Start',
  'startPage.button.createPatient': 'Create Patient',
  'startPage.searchHint': 'Search for patient',
  'starts-in': 'starts in',
  'status': 'Status',
  'stm': 'Memory',
  'stm-description': 'STM refers to your ability to hold a small amount of information in mind for a short period of time',
  'Stress sensitivity': 'Stress sensitivity',
  'stressNoteRisk': 'Anyone who receives more than 18 points may be at risk of suffering from stress/fatigue syndrome. It is then recommended that the patient contact their health center / occupational health care or manager to discuss the test results.',
  'Subcortical': 'Subcortical',
  'summary_results_and_assessment': 'Summary results and assessment',
  'Sunday': 'Sunday',
  'superAdmin': 'Super admin',
  'survey': 'Survey',
  'Suspected': 'Suspected',
  'sv': 'Svenska',
  'symptom survey': 'Symptom Survey',
  'Symptom Survey for relatives': 'Symptom Survey for relatives',
  'symptom_survey.riskLegend.hint': 'Summarized points are shown in relation to the questions answered by a relative. High points indicate several areas of worsening cognitive status. "Do not know / cannot judge" answers are not included. Maximum points when all questions are answered are shown in parentheses.',
  'SymptomSurvey_version': 'Symptom Survey',
  'systolic-pressure': 'My usual systolic pressure',
  'termsAndConditions': 'Terms and Conditions',
  'Test duration': 'Test duration',
  'testSettings': 'Application tests settings',
  'testStatus.abortedOrError': 'Aborted',
  'testStatus.done': 'Available results',
  'testStatus.initiated': 'Initiated',
  'testStatus.notSent': 'Not activated',
  'testStatus.sent': 'Activated',
  'testStatus.viewed': 'Reviewed',
  'testStatus.viewedWithError': 'Aborted / Reviewed',
  'Text chat': 'Text chat',
  'text-chat': 'Text chat',
  'Text-message history': 'Text-message history',
  'textMessageToPatient.BrainHealth': 'Brain health',
  'textMessageToPatient.BrainHealth.messageExample': 'Through this link, you can access valuable information about brain health</br>https://www.minnesmottagningen.se/hall-hjarnan-frisk/hallhjarnanfrisk</br>Please note! You cannot reply to this text message.',
  'textMessageToPatient.FeedBack': 'Request feedback',
  'textMessageToPatient.FeedBack.messageExample': 'It would be valuable for us if you could answer a few short questions about your contact with us. The questions take a maximum of 2 minutes to answer, and the responses are completely anonymous.</br>You answer the questions by clicking on this link:</br>https://forms.gle/1vvzJU5Z6qfidV9J7</br>Kind regards,</br>Minnesmottagningen.se</br>Note: You cannot reply to this message',
  'textMessageToPatient.loginCode.messageExample': 'Download the app - minnesmottagningen.se/app</br>Log in with code: XXXXX </br>Note: You cannot reply to this message',
  'textMessageToPatient.MissedAppointment': 'Missed video appointment',
  'textMessageToPatient.MissedAppointment.messageExample': 'You missed your scheduled video meeting today.</br>To avoid further charges, please contact us to reschedule your appointment.</br>You can reach us at 010-750 07 26, Monday to Friday, from 09:00 to 15:00 or at info@minnesmottagningen.se.</br>Kind regards,</br>Minnesmottagningen.se</br>Note: You cannot reply to this message',
  'textMessageToPatient.NoFreeCard': 'No free card - payment link',
  'textMessageToPatient.NoFreeCard.messageExample': 'Missing payment of patient fee.</br>While booking appointment, information about free card was incorrectly provided.</br>Therefore, we ask you to pay a patient fee of 300 kronor through the following link:</br>https://buy.stripe.com/4gw17fa4r8eG8ScfZ3</br>Kind regards,</br>Minnesmottagningen.se</br>Note: You cannot reply to this message',
  'textMessageToPatient.RefundForPatient': 'Refund for patient - due to free card',
  'textMessageToPatient.RefundForPatient.messageExample': 'Refund of patient fee.</br>We will refund the patient fee of 300 kronor because our check revealed that you have a valid free card.</br>Please note that it may take a few days for the refund to appear on the payment method you chose when booking your appointment.</br>Kind regards,</br>Minnesmottagningen.se</br>Note: You cannot reply to this message',
  'textMessageToPatient.ReminderForMeeting': 'Meeting reminder',
  'textMessageToPatient.ReminderForMeeting.messageExample': 'Meeting reminder.</br>Your video meeting will begin soon.</br>Log in here to start the video meeting:</br>Minnesmottagningen.se/patientportal</br>Kind regards,</br>Minnesmottagningen.se</br>Note: You cannot reply to this message',
  'textMessageToPatient.ReviewedByDoctor': 'Analysed by physician',
  'textMessageToPatient.ReviewedByDoctor.messageExample': 'Your responses and test results have been reviewed by a doctor.</br>You will receive the results, assessment, and recommendations during your scheduled video meeting.</br>Kind regards,</br>Minnesmottagningen.se</br>Note: You cannot reply to this message',
  'textMessageToPatient.TestReminder': 'Test reminder',
  'textMessageToPatient.TestReminder.messageExample': 'Action required.</br>There are incomplete tasks that need to be completed. Log in to the app to finish the remaining parts.</br>www.Minnesmottagningen.se/app</br>Kind regards,</br>Minnesmottagningen.se</br>Note: You cannot reply to this message',
  'textMessageToPatient.ThankYou': 'Completed memory assessment',
  'textMessageToPatient.ThankYou.messageExample': 'Information is now available in the patient portal through the following link:</br>www.minnesmottagningen.se/patientportal</br>Note: You cannot reply to this message',
  'textMessageToPatient.TriedToReachOut': 'Tried to reach out',
  'textMessageToPatient.TriedToReachOut.messageExample': 'We have tried to reach you.</br>You can reach us at 010-750 07 26, Monday to Friday, from 09:00 to 15:00 or at info@minnesmottagningen.se.</br>Kind regards,</br>Minnesmottagningen.se</br>Note: You cannot reply to this message',
  'Thank you for choosing Geras Solutions!': 'Thank you for choosing Geras Solutions!',
  'The email to the patient has now been sent': 'The email to the patient has now been sent',
  'therapist': 'Occupational therapist',
  'Thought tempo': 'Thought tempo',
  'threeDots': '...',
  'Thursday': 'Thursday',
  'Time perception 1': 'Time perception 1',
  'Time perception 2': 'Time perception 2',
  'Time to take test': 'Time to take test',
  'time-used': 'Time used',
  'title': 'Patient information',
  'To confirm invitation press button below': 'To confirm invitation press button below',
  'Today': 'Today',
  'today': 'today',
  'Tomorrow': 'Tomorrow',
  'tomorrow': 'tomorrow',
  'Total points': 'Total points',
  'Total time': 'Total time',
  'totalPatients.counter.plural': 'patients',
  'totalPatients.counter.singular': 'patient',
  'transcription from': 'Original transcription',
  'Transcription of answers': 'Transcription of answers',
  'transcription to': 'New transcription',
  'try': 'Attempt',
  'Tuesday': 'Tuesday',
  'Type here...': 'Type here...',
  'Type to search patient': 'Type to search patient',
  'Typical authentic patient cases, not scientifically validated': 'Typical authentic patient cases, not scientifically validated',
  'Underweight': 'Underweight',
  'upcomingMeetings': 'Upcoming meetings',
  'username': 'Username',
  'va': 'Visuospatial abilities',
  'va-description': 'VSA refer to your capacity to identify objects in a space and how they are spatially related.',
  'Vascular dementia - VaD': 'Vascular dementia - VaD',
  'Very severely underweight': 'Very severely underweight',
  'video-appointment': 'Video appointment',
  'video-call': 'Video call',
  'voucherCode': 'Generate Voucher Code',
  'voucherCodeStats': 'Voucher Code Statistic',
  'Waiting for user to join...': 'Waiting for user to join...',
  'Walking pattern': 'Walking pattern',
  'We have e-mailed your password reset link': 'If your account exists in our system you will receive a password reset email.',
  'We strongly recommend do not reload the page.': 'We strongly recommend do not reload the page.',
  'Wednesday': 'Wednesday',
  'Week': 'Week',
  'weight': 'Weight',
  'welcome': 'Welcome',
  'welcome_geras_1': 'Welcome to Geras Solutions',
  'welcome_geras_2': 'Web portal for health care professionals',
  'Why did you download Geras app?': 'Why did you download Geras app?',
  'whyGeras': 'Why did you download Geras app?',
  'will start in': 'will start in',
  'with': 'with',
  'wm': 'Attention, concentration, working memory',
  'wm-description': 'These are related to your short-term memory and refer to your ability to manipulate and organise information stored in your short-term memory.',
  'words:': 'Words: ',
  'Write patient’s name, personal number, or email': 'Write patient’s name, personal number, or patient-id',
  'Write your message here': 'Write your message here',
  'Write your opinion, which will be sent to the patient': 'Write your opinion, which will be sent to the patient',
  'writeComment': 'Write a comment',
  'Writing ability': 'Writing ability',
  'years': 'years',
  'yearsOld': 'years old',
  'Yes': 'Yes',
  'You can restore this user later': 'You can restore the patient later',
  'You successfully changed your password': 'You successfully changed your password',
  'zh': '中文',
};
 export default trans;